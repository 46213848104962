import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    divisions: [],
  },
  getters: {
    active: state => state.divisions.filter(division => division.active),
  },
  mutations: {
    UPDATE_DIVISIONS(state, divisions) {
      state.divisions = divisions
    },
    RESET_STATE(state) {
      state.divisions = []
    },
  },
  actions: {
    async getDivisions({ commit }, payload) {
      const { data } = await axios.get(`/seasons/${payload.season}/divisions`)

      commit('UPDATE_DIVISIONS', data.data)

      return data.data
    },
  },
}
