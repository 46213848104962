export default [
  {
    path: '/players/:player',
    name: 'player.edit',
    component: () => import('@/views/player/Player.vue'),
    props: true,
    meta: {
      pageTitle: 'Edit Player',
      navActiveLink: 'players',
      breadcrumbPortal: true,
      showFooter: true,
    },
  },
]
