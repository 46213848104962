import { $themeBreakpoints } from '@themeConfig'
import deleteModal from './delete-modal'
import sport from './sport'
import address from './address'

export default {
  namespaced: true,
  modules: {
    deleteModal,
    sport,
    address,
  },
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    breadcrumb: null,
    pageTitle: null,
    pageImage: null,
    contentAreaWrapper: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_PAGE_TITLE(state, val) {
      state.pageTitle = val
    },
    UPDATE_PAGE_IMAGE(state, val) {
      state.pageImage = val
    },
    UPDATE_CONTENT_AREA_WRAPPER(state, val) {
      state.contentAreaWrapper = val
    },
  },
  actions: {},
}
