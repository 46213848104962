import seasons from './seasons'
import divisions from './divisions'
import teams from './teams'
import venues from './venues'

export default {
  namespaced: true,
  modules: {
    seasons,
    divisions,
    teams,
    venues,
  },
  state: {
    event: null,
  },
  getters: {
    isMaleFemale: state => state.event.gender === 'male & female',
  },
  mutations: {
    UPDATE_EVENT(state, event) {
      state.event = event
    },
  },
  actions: {
    resetAllModules({ commit }) {
      commit('UPDATE_EVENT', null)
      // Dispatch reset mutations for all modules.
      commit('seasons/RESET_STATE')
      commit('divisions/RESET_STATE')
      commit('teams/RESET_STATE')
      commit('venues/RESET_STATE')
    },
  },
}
