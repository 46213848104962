<template>
  <b-button
    :disabled="isLoading || invalid || disabled"
    :variant="variant"
    :type="type"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-if="!isLoading" />
    <b-spinner
      v-show="isLoading"
      small
    />
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  inheritAttrs: false,
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'submit',
    },
  },
}
</script>

<style lang="scss" scoped>
.btn:disabled {
  cursor: not-allowed;
}
</style>
