import axios from 'axios'

export default {
  namespaced: true,
  state: {
    states: [],
    countries: [],
    timeZones: [],
  },
  getters: {
    withTextAndValue: state => field => state[field].map(address => ({ text: address.id, value: address.id })),
    statesOptions: (state, getters) => [{ text: 'Select State', value: null, disabled: true }, ...getters.withTextAndValue('states')],
    countriesOptions: (state, getters) => [{ text: 'Select Country', value: null, disabled: true }, ...getters.withTextAndValue('countries')],
  },
  mutations: {
    SET_STATES(state, states) {
      state.states = states
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries
    },
    SET_TIME_ZONES(state, timeZones) {
      state.timeZones = timeZones
    },
  },
  actions: {
    async getStates({ commit }) {
      const response = await axios.get(`${process.env.VUE_APP_API_VERSION}/states`, { baseURL: process.env.VUE_APP_BASE_URL })

      commit('SET_STATES', response.data.data)
    },
    async getCountries({ commit }) {
      const response = await axios.get(`${process.env.VUE_APP_API_VERSION}/countries`, { baseURL: process.env.VUE_APP_BASE_URL })

      commit('SET_COUNTRIES', response.data.data)
    },
    async getTimeZones({ commit }) {
      const response = await axios.get(`${process.env.VUE_APP_API_VERSION}/time-zones`, { baseURL: process.env.VUE_APP_BASE_URL })

      commit('SET_TIME_ZONES', response.data.data)
    },
  },
}
