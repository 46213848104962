import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    teams: [],
  },
  getters: {
    teamsInDivision: state => divisionId => state.teams.filter(team => {
      if (divisionId === 'All') {
        return true
      }

      // noinspection EqualityComparisonWithCoercionJS
      return team.division?.id == divisionId
    }),
  },
  mutations: {
    UPDATE_TEAMS(state, teams) {
      state.teams = teams
    },
    RESET_STATE(state) {
      state.teams = []
    },
  },
  actions: {
    async getTeams({ commit }, payload) {
      const { data } = await axios.get(`/teams?${payload.query}`)

      commit('UPDATE_TEAMS', data.data)

      return data.data
    },
  },
}
