import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    name: null,
    icon: null,
    playingSurfaceType: null,
    positions: [],
    sports: [
      { name: 'basketball', icon: 'fa-light fa-basketball-hoop', playingSurfaceType: 'Court' },
      { name: 'volleyball', icon: 'fa-light fa-volleyball', playingSurfaceType: 'Court' },
    ],
  },
  mutations: {
    UPDATE_SPORT(state, sportName) {
      const sport = state.sports.find(s => s.name === sportName)

      if (sport) {
        Object.assign(state, { ...sport })
      }
    },
    UPDATE_POSITIONS(state, positions) {
      state.positions = positions
    },
  },
  actions: {
    async getPositions({ commit, state }) {
      const response = await axios.get(`/positions?sport=${state.name}`)
      commit('UPDATE_POSITIONS', response.data.data)
    },
  },
}
