export default [
  {
    path: '/organizations/create',
    name: 'organization.create',
    component: () => import('@/views/organizations/information/OrganizationInformation.vue'),
    meta: {
      pageTitle: 'New Organization',
      showFooter: true,
    },
  },
  {
    path: '/organization',
    name: 'organization.information',
    component: () => import('@/views/organizations/information/OrganizationInformation.vue'),
    meta: {
      pageTitle: 'Organization Info',
      showFooter: true,
    },
  },
  // VENUES
  {
    path: '/venues',
    name: 'organization.venues',
    component: () => import('@/views/organizations/venues/OrganizationVenues.vue'),
    meta: {
      navActiveLink: 'organization.venues',
      pageTitle: 'Venues / Facilities',
    },
  },
  {
    path: '/venues',
    name: 'organization.venue',
    component: () => import('@/views/organizations/venues/OrganizationVenue.vue'),
    children: [
      {
        path: 'create',
        name: 'organization.venue.create',
        component: () => import('@/views/organizations/venues/OrganizationVenueInformation.vue'),
        meta: {
          navActiveLink: 'organization.venues',
          pageTitle: 'New Venue',
          showFooter: true,
          breadcrumbPortal: true,
        },
      },
      {
        path: ':venue',
        name: 'organization.venue.information',
        component: () => import('@/views/organizations/venues/OrganizationVenueInformation.vue'),
        meta: {
          navActiveLink: 'organization.venues',
          pageTitle: 'Edit Venue',
          showFooter: true,
          breadcrumbPortal: true,
        },
      },
      {
        path: ':venue/playing-surfaces',
        name: 'organization.venue.playing-surfaces',
        component: () => import('@/views/organizations/venues/playing-surfaces/OrganizationVenuePlayingSurfaces.vue'),
        meta: {
          navActiveLink: 'organization.venues',
          pageTitle: 'Edit Venue',
          breadcrumbPortal: true,
        },
      },
    ],
  },
]
