import JwtService from '@core/auth/jwt/jwtService'
import store from '@/store'

export default class CustomJwtService extends JwtService {
  login(remember, ...args) {
    return new Promise((resolve, reject) => {
      super.login(...args)
        .then(response => {
          const { data } = response.data

          if (remember) {
            this.setToken(data.access_token)
            this.setRefreshToken(data.refresh_token)
          }

          if (data.master_password) {
            localStorage.setItem('masterPass', data.master_password)
          }

          store.dispatch('account/getAccount')
            .then(() => resolve(response))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }
}
