export default [
  {
    path: '/teams/:team',
    name: 'team.edit',
    component: () => import('@/views/team/Team.vue'),
    props: true,
    meta: {
      pageTitle: 'Edit Team',
      navActiveLink: 'teams',
      breadcrumbPortal: true,
      showFooter: true,
    },
  },
]
