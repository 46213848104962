import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFlash() {
  function flashChangesSaved(message = 'Your changes have been saved!') {
    Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Success',
        variant: 'success',
        text: message,
        icon: 'CheckIcon',
      },
    })
  }

  function flashError(message = null) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: `Error! ${message}`,
        variant: 'danger',
        icon: 'XIcon',
      },
    })
  }

  return {
    flashChangesSaved,
    flashError,
  }
}
