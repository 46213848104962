import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    venues: [],
  },
  getters: {},
  mutations: {
    UPDATE_VENUES(state, venues) {
      state.venues = venues
    },
    RESET_STATE(state) {
      state.venues = []
    },
  },
  actions: {
    async getVenues({ rootState, commit }, payload) {
      const { data } = await axios.get(`/organizations/${rootState.account.activeOrganization}/venues?${payload.query}`)

      commit('UPDATE_VENUES', data.data)

      return data.data
    },
  },
}
