export default [
  {
    path: '/leagues',
    name: 'leagues',
    component: () => import('@/views/events/EventsList.vue'),
    meta: {
      pageTitle: 'Leagues',
      eventType: 'league',
    },
  },
  {
    path: '/leagues/create',
    name: 'league.create',
    component: () => import('@/views/events/information/EventInformation.vue'),
    meta: {
      pageTitle: 'New League',
      navActiveLink: 'leagues',
      showFooter: true,
    },
  },
  {
    path: '/leagues',
    component: () => import('@/views/events/Event.vue'),
    children: [
      {
        path: ':event/information',
        name: 'event.information',
        navTitle: 'Info',
        icon: 'fa-circle-info',
        component: () => import('@/views/events/information/EventInformation.vue'),
        meta: {
          navActiveLink: 'leagues',
          showFooter: true,
        },
      },
      {
        path: ':event/seasons',
        name: 'event.seasons',
        navTitle: 'Seasons',
        icon: 'fa-calendar-clock',
        component: () => import('@/views/events/seasons/EventSeasons.vue'),
        meta: {
          navActiveLink: 'leagues',
          keepLoading: true,
        },
      },
      {
        path: ':event/seasons/:season?',
        name: 'event.season',
        component: () => import('@/views/events/seasons/EventSeason.vue'),
        props: true,
        meta: {
          navActiveLink: 'leagues',
          showFooter: true,
        },
      },
      {
        path: ':event/venues',
        name: 'event.venues',
        navTitle: 'Venues',
        icon: 'fa-warehouse-full',
        component: () => import('@/views/events/venues/EventVenues.vue'),
        meta: {
          navActiveLink: 'leagues',
          // keepLoading: true,
        },
      },
      {
        path: ':event/manage',
        name: 'event.manage',
        navTitle: 'Manage',
        icon: 'fa-people-roof',
        component: () => import('@/views/events/manage/EventManage.vue'),
        meta: {
          navActiveLink: 'leagues',
        },
        children: [
          {
            path: 'divisions',
            name: 'event.divisions',
            navTitle: 'Divisions',
            icon: 'fa-split',
            component: () => import('@/views/events/manage/divisions/EventManageDivisions.vue'),
            meta: {
              navActiveLink: 'leagues',
              keepLoading: true,
            },
          },
          {
            path: 'teams',
            name: 'event.teams',
            navTitle: 'Teams',
            icon: 'fa-screen-users',
            component: () => import('@/views/events/manage/teams/EventManageTeams.vue'),
            meta: {
              navActiveLink: 'leagues',
              keepLoading: true,
            },
          },
          {
            path: 'players',
            name: 'event.players',
            navTitle: 'Players',
            icon: 'fa-users',
            component: () => import('@/views/events/manage/players/EventManagePlayers.vue'),
            meta: {
              navActiveLink: 'leagues',
              keepLoading: true,
            },
          },
          {
            path: 'schedule',
            name: 'event.schedule',
            navTitle: 'Schedule',
            icon: 'fa-calendar-week',
            component: () => import('@/views/events/manage/schedule/EventManageSchedule.vue'),
            meta: {
              navActiveLink: 'leagues',
              keepLoading: true,
            },
          },
          {
            path: 'schedule/games/:game/stats',
            name: 'event.game.stats',
            component: () => import('@/views/events/manage/games/EventManageGameStats.vue'),
            props: true,
            meta: {
              navActiveLink: 'leagues',
              showFooter: true,
              disableSeason: true,
            },
          },
        ],
      },
      {
        path: ':event/website',
        name: 'event.website',
        navTitle: 'Website',
        icon: 'fa-browser',
        meta: {
          navActiveLink: 'leagues',
        },
        component: () => import('@/views/events/website/EventWebsite.vue'),
        children: [
          {
            path: 'news',
            name: 'event.website.news',
            navTitle: 'News',
            icon: 'fa-newspaper',
            component: () => import('@/views/events/website/news/EventWebsiteNewsArticles.vue'),
            meta: {
              navActiveLink: 'leagues',
              keepLoading: true,
            },
          },
        ],
      },
      {
        path: ':event/settings',
        name: 'event.settings',
        navTitle: 'Settings',
        icon: 'fa-gear',
        component: () => import('@/views/events/settings/EventSettings.vue'),
        meta: {
          navActiveLink: 'leagues',
          showFooter: true,
        },
      },
    ],
  },
]
