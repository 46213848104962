import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import auth from '@/router/auth'
import organization from '@/router/organization'
import league from '@/router/league'
import team from '@/router/team'
import player from '@/router/player'

import axiosIns from '@/libs/axios'
import store from '@/store'
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...organization,
    ...league,
    ...team,
    ...player,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'leagues' },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  if (!store.state.account.loaded) {
    await store.dispatch('account/getAccount')
  }

  const isLoggedIn = isUserLoggedIn()

  // If user is logged in set the base url to include the API version.
  axiosIns.defaults.baseURL = isLoggedIn ? `${process.env.VUE_APP_BASE_URL}/${process.env.VUE_APP_API_VERSION}` : process.env.VUE_APP_BASE_URL

  if (!canNavigate(to)) {
    // Redirect to login if not logged in.
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in.
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach(to => {
  if (to.meta.pageTitle) {
    store.commit('app/UPDATE_PAGE_TITLE', null)
    store.commit('app/UPDATE_PAGE_IMAGE', null)
  }

  if (store.state.app.contentAreaWrapper) {
    setTimeout(() => {
      store.commit('app/UPDATE_CONTENT_AREA_WRAPPER', false)
    }, 350)
  }

  // Remove splash screen initial loading.
  const appLoading = document.getElementById('loading-bg')

  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
