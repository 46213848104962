import Vue from 'vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faSitemap, faCircleInfo, faWarehouseFull, faLink, faCalendarStar, faCalendarClock, faSplit, faScreenUsers, faUsers, faCalendarWeek, faPeopleRoof, faHouse, faTrophyStar, faShirtRunning, faWhistle, faRightLeft, faFilter, faArrowLeft, faBasketballHoop, faVolleyball, faGear, faFileImport, faBrowser, faNewspaper,
} from '@fortawesome/pro-light-svg-icons'

/* import specific icons */
import {
  faSquareFacebook, faTwitter, faYoutube, faInstagram,
} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(
  faSitemap,
  faCircleInfo,
  faWarehouseFull,
  faSquareFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faLink,
  faCalendarStar,
  faCalendarClock,
  faSplit,
  faScreenUsers,
  faUsers,
  faCalendarWeek,
  faPeopleRoof,
  faHouse,
  faTrophyStar,
  faShirtRunning,
  faWhistle,
  faRightLeft,
  faFilter,
  faArrowLeft,
  faBasketballHoop,
  faGear,
  faFileImport,
  faVolleyball,
  faBrowser,
  faNewspaper,
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
