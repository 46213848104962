import axios from '@/libs/axios'

const defaultState = {
  seasons: [],
  season: null,
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    latest: state => state.seasons[0],
    seasonId: state => (state.season ? state.season.id : null),
    latestSeasons: state => state.seasons.sort((a, b) => new Date(b.end_date) - new Date(a.end_date)),
  },
  mutations: {
    UPDATE_SEASONS(state, seasons) {
      state.seasons = seasons
    },
    UPDATE_SEASON(state, value) {
      state.season = value
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState)
    },
  },
  actions: {
    async getSeasons({ commit }, payload) {
      const { data } = await axios.get(`/events/${payload.event}/seasons`)

      commit('UPDATE_SEASONS', data.data)

      return data.data
    },
  },
}
