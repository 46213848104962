<template>
  <div class="submit-buttons text-center">
    <slot name="prepend" />

    <button-loading
      v-if="saveAndNew"
      :is-loading="loading && !clicked.save"
      :disabled="loading"
      :invalid="invalid"
      class="mr-1"
      v-bind="bindAttributes(saveAndNew)"
      @click="handleSaveAndAddNewClick"
    >
      Save & New
    </button-loading>

    <button-loading
      v-if="save"
      class="mr-1"
      :is-loading="loading && clicked.save || (!saveAndNew && loading)"
      :disabled="loading"
      :invalid="invalid"
      v-bind="bindAttributes(save)"
      @click="handleSaveClick"
    >
      {{ save.text || 'Save' }}
    </button-loading>

    <b-button
      v-if="cancel"
      variant="outline-dark"
      :disabled="loading"
      v-bind="bindAttributes(cancel)"
      @click="$emit('cancel')"
    >
      {{ cancel.text || 'Cancel' }}
    </b-button>

    <slot name="append" />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import ButtonLoading from '@/components/buttons/ButtonLoading.vue'

export default {
  components: {
    BButton,
    ButtonLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: [Object, Boolean],
      default: () => ({}),
    },
    saveAndNew: {
      type: [Object, Boolean],
      default: false,
    },
    save: {
      type: [Object, Boolean],
      default: () => ({}),
    },
  },
  data() {
    return {
      clicked: {
        addNew: false,
        save: false,
      },
    }
  },
  watch: {
    loading(value) {
      if (value === false) {
        this.clicked.addNew = false
        this.clicked.save = false
      }
    },
  },
  methods: {
    handleSaveAndAddNewClick() {
      this.clicked.addNew = true
      this.$emit('save-and-new')
      this.$emit('submit', 'save-and-new')
    },
    handleSaveClick() {
      this.clicked.save = true
      this.$emit('save')
      this.$emit('submit', 'save')
    },
    bindAttributes(attributes) {
      return typeof attributes === 'boolean' ? {} : attributes
    },
  },
}
</script>

<style lang="scss">
.submit-buttons .btn {
  padding: 10px 25px;
  @media (max-width: 576px) {
    & {
      padding: 10px 15px;
    }
  }
}

.modal-footer .btn {
  padding: 10px 20px;
}
</style>
