import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    loaded: false,
    user: null,
    activeOrganization: null,
    organizations: [],
  },
  getters: {
    organization: state => state.organizations.find(organization => organization.id === state.activeOrganization) || {},
  },
  mutations: {
    UPDATE_LOADED(state, loaded) {
      state.loaded = loaded
    },
    UPDATE_USER(state, user) {
      state.user = user
    },
    UPDATE_ACTIVE_ORGANIZATION(state, id) {
      state.activeOrganization = id
      // Remember the organization, so it can be loaded when the user returns.
      localStorage.setItem('activeOrganization', id)
    },
    UPDATE_ORGANIZATION(state, organization) {
      state.organizations.splice(
        state.organizations.find(org => org.id === organization.id),
        1,
        organization,
      )
    },
    UPDATE_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
    ADD_ORGANIZATION(state, organization) {
      state.organizations.push(organization)
    },
  },
  actions: {
    async getAccount({ state, commit, dispatch }) {
      await new Promise(resolve => {
        dispatch('getUser')
          .then(() => {
            // User is not signed in.
            if (!state.user) {
              resolve()
              return
            }

            // Get all user's organizations.
            dispatch('getOrganizations')
              .then(() => {
                commit(
                  'UPDATE_ACTIVE_ORGANIZATION',
                  parseInt(localStorage.getItem('activeOrganization')) || state.organizations[0]?.id,
                )

                resolve()
              })
          })
      })

      commit('UPDATE_LOADED', true)
    },
    async getUser({ commit }) {
      try {
        const { data } = await axios.get('/auth/user', { baseURL: process.env.VUE_APP_BASE_URL })
        // Save user's data into local storage, so they can be signed back in when they return.
        localStorage.setItem('userData', JSON.stringify(data.data))

        commit('UPDATE_USER', data.data)
      } catch (error) {
        localStorage.removeItem('userData')
        commit('UPDATE_USER', false)
      }
    },
    async getOrganizations({ commit }) {
      const response = await axios.get(`/${process.env.VUE_APP_API_VERSION}/organizations`, { baseURL: process.env.VUE_APP_BASE_URL })

      commit('UPDATE_ORGANIZATIONS', response.data.data)
    },
  },
}
