const getDefaultState = () => ({
  title: '',
  text: '',
  deleteButtonText: 'Delete',
  visible: false,
  itemName: null,
  endpoint: null,
  flashMessage: undefined,
  afterDelete: () => {},
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    UPDATE_DELETE_MODAL(state, payload) {
      Object.assign(state, {
        visible: true,
        ...payload,
      })
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {},
}
