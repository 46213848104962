<template>
  <Modal
    ref="modal"
    v-model="visible"
    header-bg-variant="danger"
    :title="title"
    @hidden="RESET_STATE"
  >
    <template #details>
      <b-card-title>
        {{ itemName }}
      </b-card-title>
      <b-card-text>
        <span
          v-if="text"
          v-html="text"
        />
        <template v-else>
          Are you sure you want to {{ deleteButtonText.toLowerCase() }} this?
        </template>
      </b-card-text>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <button-loading
        :is-loading="isLoading"
        size="sm"
        variant="danger"
        @click="deleteItem"
      >
        {{ deleteButtonText }}
      </button-loading>

      <b-button
        size="sm"
        variant="outline-dark"
        @click="UPDATE_DELETE_MODAL({ visible: false })"
      >
        Cancel
      </b-button>
    </template>
  </Modal>
</template>

<script>
import {
  BCardTitle, BCardText, BButton,
} from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'
import ButtonLoading from '@/components/buttons/ButtonLoading.vue'
import { mapMutations, mapState } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashChangesSaved, flashError } = useFlash()

export default {
  components: {
    BCardTitle,
    BCardText,
    BButton,
    Modal,
    ButtonLoading,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: mapState('app/deleteModal', ['title', 'text', 'deleteButtonText', 'visible', 'itemName', 'endpoint', 'afterDelete', 'flashMessage']),
  methods: {
    ...mapMutations('app/deleteModal', ['UPDATE_DELETE_MODAL', 'RESET_STATE']),
    deleteItem() {
      if (!this.endpoint) {
        this.afterDelete()
        return
      }

      this.isLoading = true

      this.$http.delete(`${this.endpoint}`)
        .then(() => {
          this.afterDelete()
          flashChangesSaved(this.flashMessage || `${this.itemName} has been successfully ${this.deleteButtonText.toLowerCase()}d!`)
        })
        .catch(error => {
          flashError(`Request failed: ${error}`)
        })
        .finally(() => {
          this.isLoading = false
          this.UPDATE_DELETE_MODAL({ visible: false })
        })
    },
  },
}
</script>

<style scoped>

</style>
