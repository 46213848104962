<template>
  <b-modal
    ref="bModal"
    :header-bg-variant="$attrs['header-bg-variant'] || 'secondary'"
    :header-text-variant="$attrs['header-text-variant'] || 'white'"
    :header-close-variant="$attrs['header-close-variant'] || 'dark'"
    :cancel-variant="$attrs['cancel-variant'] || 'light'"
    hide-header-close
    :centered="$attrs['centered'] === undefined ? true : $attrs['centered']"
    no-close-on-backdrop
    no-close-on-esc
    :visible="$attrs.value || $attrs['visible']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <section
      v-if="$slots.details || typeof details === 'object'"
      class="text-center my-2"
    >
      <slot name="details">
        <b-card-title>{{ details.title }}</b-card-title>

        <b-card-text class="my-2">
          {{ details.text }}
        </b-card-text>
      </slot>
    </section>

    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots)"
      :slot="slot"
      :name="slot"
    />

    <!-- Footer slot -->
    <template
      v-if="!$slots['modal-footer'] && !$attrs.hideFooter && footerPortal"
      #modal-footer
    >
      <submit-buttons
        v-bind="submitButtons"
        v-on="$listeners"
      />
    </template>
  </b-modal>
</template>

<script>
import { BModal, BCardTitle, BCardText } from 'bootstrap-vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'

export default {
  components: {
    BModal,
    BCardTitle,
    BCardText,
    SubmitButtons,
  },
  props: {
    footerPortal: {
      type: [String, Boolean],
      default: 'modal-footer',
    },
    submitButtons: {
      type: Object,
      default: () => ({}),
    },
    details: {
      type: [Object, Boolean],
      default: () => ({
        title: null,
        text: null,
      }),
    },
  },
}
</script>

<style scoped>

</style>
