import axiosIns from '@/libs/axios'
import CustomJwtService from '@/auth/jwt/jwtService'

function useJwt() {
  const jwt = new CustomJwtService(axiosIns, {
    // Endpoints
    loginEndpoint: '/auth/login',
    registerEndpoint: '/auth/register',
    refreshEndpoint: '/auth/refresh-token',
    logoutEndpoint: '/auth/logout',
  })

  return {
    jwt,
  }
}

const { jwt } = useJwt()

export default jwt
